import React, {useEffect, useMemo, useState} from 'react';
import {PageHeader} from 'components/PageHeader';
import gql from 'graphql-tag';
import {TableContainer} from "shared/CommonStyles";
import {
    Pagination, Team,
    useAdminMetricsQuery,
    useAdminTeamsQuery,
    useAdminUsersQuery,
    User,
} from 'types/gqlReactTypings.generated.d';
import {orderBy} from 'lodash';
import {GqlQueryRender} from 'shared/gql/gqlQueryRender';
import {TableWrapper} from 'shared/tableWrapper';
import {formatBoolean, formatDate} from 'shared/Utilities';
import {AdminPaginator} from './components/adminPaginator';
import {query} from "express";
import {FetchResult} from "@apollo/client";
import type {MRT_ColumnDef} from "material-react-table";
import {MaterialReactTable, MRT_Row, useMaterialReactTable} from "material-react-table";
import {ExportToCsv} from "ts-export-to-csv";
import {Box, Button} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Modal} from "react-bootstrap";
import AppInput, {InputType} from "../../components/AppInput";

interface IProps {
    match?: any;
}

gql`
    query AdminTeams($pagination: Pagination) {
        adminTeams(pagination: $pagination) {
            id, name, code, location, createdAt, playerCount, archived
            coach { id, fullName }
        }
    }
`

export const AdminTeams: React.FC<IProps> = () => {
    const adminMetricsQuery = useAdminMetricsQuery();
    const [pagination, setPagination] = React.useState<Pagination>();
    const {data, refetch} = useAdminTeamsQuery({variables: {pagination: {...pagination, limit: 1000}}});
    const [gridData, setGridData] = useState([] as Team[]);
    const handlePaginationChange = (newPagination: Pagination) => {
        setPagination(newPagination);
        refetch({pagination: {...pagination, ...newPagination}});
    }

    useEffect(() => {
        if (data?.adminTeams) {
            const res = data.adminTeams as Team[];
            setGridData(res);
        }
    }, [data]);

    const columns = useMemo<MRT_ColumnDef<Team>[]>(
        () => [
            // {
            //     accessorKey: 'id',
            //     header: 'ID',
            //     size: 10,
            // },
            {
                accessorKey: 'imageUrl',
                header: 'Avatar',
                size: 50,
                enableSorting: false,
                enableColumnFilter: false,
                Cell: ({cell}) => <img alt="" style={{borderRadius: 40}} width="40" height="40"
                                       src={cell.getValue() as string}/>,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                size: 10,
            },
            {
                accessorKey: 'code',
                header: 'Code',
                size: 10,
            },
            {
                accessorKey: 'location',
                header: 'Location',
                size: 10,
            },
            {
                accessorKey: 'coach.fullName',
                header: 'Coach',
                size: 10,
            },
            {
                accessorKey: 'playerCount',
                header: 'Players',
                size: 10,
            },
            {
                accessorKey: 'createdAt',
                header: 'Created',
                // size: 150,
                filterVariant: "date-range",
                accessorFn: (user) => new Date(user.createdAt),
                Cell: ({cell}) => cell.getValue<Date>().toLocaleDateString(),
            },
            {
                accessorKey: 'archived',
                header: 'Archived',
                size: 10,
                filterVariant: "select",
                filterSelectOptions: [{label: "Yes", value: "Yes"}, {label: "No", value: "No"}],
                accessorFn: (row) => row.archived ? "Yes" : "No",
                Cell: ({cell}) =>
                    <span style={{color: cell.getValue() == 'Yes' ? 'red' : 'green'}}>{cell.getValue()}</span>,
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: gridData,
        getRowId: (row) => row.id,
        initialState: {showColumnFilters: false},
        enableRowSelection: true,
        enablePagination: false,
        muiTableBodyRowProps: {hover: true},
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default, //change default background color
        }),
        positionToolbarAlertBanner: 'bottom',
        renderTopToolbarCustomActions: ({table}) => {
            const csvExporter = new ExportToCsv({
                filename: 'teams',
                useKeysAsHeaders: true
            });
            const handleExportRows = (rows: MRT_Row<any>[]) => {
                const rowData = rows.map((row) => {
                    const data = {...row.original, coach: row.original.coach?.fullName};
                    delete data.__typename;
                    return data;
                });
                csvExporter.generateCsv(rowData);
            };
            const handleExportData = () => {
                const rowData = gridData.map((row) => {
                    const data = {...row, coach: row.coach?.fullName};
                    delete data.__typename;
                    return data;
                });
                csvExporter.generateCsv(rowData);
            };
            return (
                <Box sx={{display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap',}}>
                    <Button onClick={handleExportData} startIcon={<FileDownloadIcon/>}>
                        Export All Data
                    </Button>
                    <Button
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                        startIcon={<FileDownloadIcon/>}>
                        Export Selected Rows
                    </Button>
                </Box>
            )
        }
    });

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MaterialReactTable table={table}/>
            </LocalizationProvider>
        </>
    );

}