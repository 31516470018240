import React, {useEffect, useState} from 'react';
import gql from 'graphql-tag';
import {
    Pagination,
    useAdminUsersQuery, User,
    useSendPushToAllMutation,
} from 'types/gqlReactTypings.generated.d';
import {Col, Modal, Row} from "react-bootstrap";
import AppInput, {InputType} from "../../components/AppInput";
import {FetchResult} from "@apollo/client";

import {useMemo} from 'react';
import {
    type MRT_ColumnDef,
    type MRT_Cell,
    MaterialReactTable,
    useMaterialReactTable, MRT_Row,
} from 'material-react-table';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Box, Button} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {ExportToCsv} from "ts-export-to-csv";

interface IProps {
    match?: any;
}

gql`
    query AdminUsers($pagination: Pagination) {
        adminUsers(pagination: $pagination) {
            id
            email
            phoneNumber
            fullName
            admin
            coach
            location
            imageUrl
            position
            verified
            role
            timeZone
            archived
            createdAt,
        }
    }
`
export const AdminUsers: React.FC<IProps> = () => {
    const [pagination, setPagination] = React.useState<Pagination>();
    const {data: usersQueryData} = useAdminUsersQuery({variables: {pagination: {...pagination, limit: 1000}}});
    const [showPushModal, setShowPushModal] = useState(false);
    const [pushText, setPushText] = useState('');
    const [pushTitle, setPushTitle] = useState('');
    const [pushOwners, setPushOwners] = useState(false);
    const [pushByIds, setPushByIds] = useState(false);
    const [pushIds, setPushIds] = useState('');

    const [sendPushToAll] = useSendPushToAllMutation();
    const [users, setUsers] = useState([] as User[]);
    useEffect(() => {
        if (usersQueryData?.adminUsers) {
            const res = usersQueryData.adminUsers as User[];
            setUsers(res);
        }
    }, [usersQueryData]);

    const handleSendPush = () => {
        sendPushToAll({
            variables: {
                text: pushText,
                title: pushTitle,
                owners: pushOwners,
                ids: pushByIds ? pushIds : undefined
            },
        })
            .then((response: FetchResult) => {
                console.log('Send result:', response);

                if (response.data?.sendPushToAll) {
                    setShowPushModal(false);
                }
            })
            .catch((error: Error) => {
                window.alert('Error send push');
                console.error(error);
            });
    }

    const columns = useMemo<MRT_ColumnDef<User>[]>(
        () => [
            {
                accessorKey: 'imageUrl',
                header: 'Avatar',
                size: 50,
                enableSorting: false,
                enableColumnFilter: false,
                Cell: ({cell}) => <img alt="" style={{borderRadius: 40}} width="40" height="40"
                                       src={cell.getValue() as string}/>,
            },
            {
                accessorKey: 'fullName',
                header: 'Name',
                size: 10,
            },
            {
                accessorKey: 'email',
                header: 'Email',
                size: 10,
            },
            {
                accessorKey: 'phoneNumber',
                header: 'Phone',
                size: 10,
            },
            {
                accessorKey: 'location',
                header: 'Location',
                size: 10,
            },
            {
                accessorKey: 'position',
                header: 'Position',
                size: 10,
            },
            {
                accessorKey: 'createdAt',
                header: 'Created',
                size: 150,
                filterVariant: "date-range",
                accessorFn: (user) => new Date(user.createdAt),
                Cell: ({cell}) => cell.getValue<Date>().toLocaleDateString(),
            },
            {
                accessorKey: 'coach',
                header: 'Coach',
                size: 150,
                filterVariant: "select",
                filterSelectOptions: [{label: "Yes", value: "Yes"}, {label: "No", value: "No"}],
                accessorFn: (row) => row.coach ? "Yes" : "No",
                Cell: ({cell}) =>
                    <span style={{color: cell.getValue() == 'Yes' ? 'green' : 'red'}}>{cell.getValue()}</span>,
            },
            {
                accessorKey: 'admin',
                header: 'Admin',
                size: 150,
                filterVariant: "select",
                filterSelectOptions: [{label: "Yes", value: "Yes"}, {label: "No", value: "No"}],
                accessorFn: (row) => row.admin ? "Yes" : "No",
                Cell: ({cell}) =>
                    <span style={{color: cell.getValue() == 'Yes' ? 'green' : 'red'}}>{cell.getValue()}</span>,
            },

            {
                accessorKey: 'timeZone',
                header: 'TZ',
                size: 10,
            },
            // {
            //     accessorKey: 'proTeam.name',
            //     accessorFn: (user) => user.proTeam?.name,
            //     header: 'Pro Team',
            //     size: 10,
            // },

            {
                accessorKey: 'archived',
                header: 'Arch',
                size: 10,
                filterVariant: "select",
                filterSelectOptions: [{label: "Yes", value: "Yes"}, {label: "No", value: "No"}],
                accessorFn: (row) => row.archived ? "Yes" : "No",
                Cell: ({cell}) =>
                    <span style={{color: cell.getValue() == 'Yes' ? 'red' : 'green'}}>{cell.getValue()}</span>,
            },
        ],
        [],
    );
    const table = useMaterialReactTable({
        columns,
        data: users,
        getRowId: (row) => row.id,
        initialState: {showColumnFilters: false},
        enableRowSelection: true,
        enablePagination: false,
        muiTableBodyRowProps: {hover: true},
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default, //change default background color
        }),
        positionToolbarAlertBanner: 'bottom',
        renderTopToolbarCustomActions: ({table}) => {
            const csvExporter = new ExportToCsv({
                filename: 'users',
                useKeysAsHeaders: true
            });
            const handleExportRows = (rows: MRT_Row<User>[]) => {
                const rowData = rows.map((row) => row.original);
                csvExporter.generateCsv(rowData);
            };
            const handleExportData = () => {
                csvExporter.generateCsv(users);
            };
            return (
                <Box sx={{display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap',}}>
                    <Button onClick={() => {
                        setShowPushModal(true)
                    }}>Send push to all users</Button>
                    <Button onClick={handleExportData} startIcon={<FileDownloadIcon/>}>
                        Export All Data
                    </Button>
                    <Button
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                        startIcon={<FileDownloadIcon/>}>
                        Export Selected Rows
                    </Button>
                </Box>
            )
        }
    });

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MaterialReactTable table={table}/>
            </LocalizationProvider>
            <Modal show={showPushModal} onHide={() => {
                setShowPushModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Send push to all users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AppInput
                        type={InputType.TEXT}
                        value={pushText}
                        onChange={(val: string) => {
                            setPushText(val);
                        }}
                        label='Text'
                    />
                    <AppInput
                        type={InputType.TEXT}
                        value={pushTitle}
                        onChange={(val: string) => {
                            setPushTitle(val);
                        }}
                        label='Title'
                    />
                    <Row>
                        <Col md={6}>
                            <AppInput
                                type={InputType.CHECKBOX}
                                value={pushOwners}
                                onChange={(val: boolean) => {
                                    setPushOwners(val);
                                }}
                                placeholder='Push to owners'
                                // fullWidth={true}
                                medInputCol={true}
                            />
                        </Col>
                        <Col md={6}>
                            <AppInput
                                type={InputType.CHECKBOX}
                                value={pushByIds}
                                onChange={(val: boolean) => {
                                    setPushByIds(val);
                                }}
                                placeholder='Push by ids'
                                fullWidth={true}
                                medInputCol={true}
                            />
                        </Col>
                    </Row>
                    {pushByIds &&
                        <AppInput
                            type={InputType.TEXT_AREA}
                            value={pushIds}
                            onChange={(val: string) => {
                                setPushIds(val);
                            }}
                            disabled={!pushByIds}
                            label='User ID`s'
                        />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setShowPushModal(false)
                    }}>
                        Close
                    </Button>
                    <Button onClick={handleSendPush}>SEND</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
