import React from 'react';
import { PageHeader } from 'components/PageHeader';
import gql from 'graphql-tag';
import { TableContainer } from "shared/CommonStyles";
import { ProTeamInput, useAddProTeamMutation, useProTeamsQuery, } from 'types/gqlReactTypings.generated.d';
import { GqlQueryRender } from 'shared/gql/gqlQueryRender';
import { TableWrapper } from 'shared/tableWrapper';
import { hookStateChangeInjector } from 'shared/Utilities';
import { Button, Col, Row } from 'react-bootstrap';
import AppInput, { InputType } from 'components/AppInput';
import { FileDropzone } from 'components/fileDropzone';

interface IProps {
  match?: any;
}

gql`
  query ProTeams {
    proTeams {
      id, imageUrl, name, archived
    }
  }

  mutation AddProTeam($input: ProTeamInput!) {
    addProTeam(input: $input) {
      id
    }
  }
`

export const AdminProTeams: React.FC<IProps> = () => {
  const query = useProTeamsQuery({});
  const [addProTeamMutation] = useAddProTeamMutation();


  const [input, setInput] = React.useState<ProTeamInput>({
    imageUrl: '',
    name: ''
  });

  const change = hookStateChangeInjector(input, setInput);

  const onSubmit = () => {
    addProTeamMutation({
      variables: {
        input,
      }
    }).then(() => {
      query?.refetch();
      window.alert('Pro team created');
    }).catch((err) => {
      window.alert('Error creating pro team');
      console.error(err);
    })
  }

  return (
    <>
      <PageHeader title={`Pro Teams (${query.data?.proTeams.length ?? '-'} total)`} />
      <TableContainer>
        <TableWrapper columns={[
          'ID',
          'Image',
          'Image URL',
          'Name',
        ]}>
          <GqlQueryRender query={query}>
            {({ proTeams }) => {
              return (
                <tbody>
                  {proTeams
                    .map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td><img src={item.imageUrl} style={{ width: 100, height: 100, objectFit: 'contain' }} /></td>
                          <td>{item.imageUrl}</td>
                          <td>{item.name}</td>
                        </tr>
                      )
                    })}
                </tbody>
              )
            }}
          </GqlQueryRender>
        </TableWrapper>
      </TableContainer>

      <h4>Add New Pro Team</h4>
      <Row style={{ marginBottom: 20 }}>
        <Col md={2}>
          <AppInput type={InputType.TEXT} value={input.imageUrl} onChange={change('imageUrl')} label='Image URL*' />
          <FileDropzone onSuccess={file => {
            change('imageUrl')(file);
          }} accept={{ 'image/*': [] }} />
        </Col>
        <Col md={2}>
          <AppInput type={InputType.TEXT} value={input.name} onChange={change('name')} label='Name' />
        </Col>
      </Row>
      <Button variant='success' style={{ width: '100%' }} onClick={onSubmit}>Submit</Button>
    </>
  );
}
