import React from 'react';
import { PageHeader } from 'components/PageHeader';
import gql from 'graphql-tag';
import { TableContainer } from "shared/CommonStyles";
import { LiveStreamInput, Pagination, useAdminLiveStreamsQuery, useAdminMetricsQuery, useCreateLiveStreamMutation, } from 'types/gqlReactTypings.generated.d';
import { GqlQueryRender } from 'shared/gql/gqlQueryRender';
import { TableWrapper } from 'shared/tableWrapper';
import { formatBoolean, formatDate, hookStateChangeInjector } from 'shared/Utilities';
import { AdminPaginator } from './components/adminPaginator';
import { Button, Col, Row } from 'react-bootstrap';
import AppInput, { InputType } from 'components/AppInput';
import moment from 'moment';
import { ADMIN_BASE, AdminRoutes, getIdSluggedPath } from 'shared/Routes';
import { Link } from 'react-router-dom';
import { FileDropzone } from 'components/fileDropzone';

interface IProps {
  match?: any;
}

gql`
  query AdminLiveStreams($pagination: Pagination) {
    adminLiveStreams(pagination: $pagination) {
      id, imageUrl, zoomId, startDate, endDate, title, description, archived, createdAt
    }
  }

  mutation CreateLiveStream($liveStreamInput: LiveStreamInput!) {
    createLiveStream(liveStreamInput: $liveStreamInput) {
      id
    }
  }
`

export const AdminLiveStreams: React.FC<IProps> = () => {
  const [pagination, setPagination] = React.useState<Pagination>();
  const query = useAdminLiveStreamsQuery({ variables: { pagination: { ...pagination, limit: 200, }, } });
  const adminMetricsQuery = useAdminMetricsQuery();
  const [createLiveStreamMutation] = useCreateLiveStreamMutation();


  const [input, setInput] = React.useState<LiveStreamInput>({
    imageUrl: '',
    startDate: moment().add(1, 'day').toDate(),
    endDate: moment().add(1, 'day').toDate(),
    title: '',
    description: ''
  });

  const change = hookStateChangeInjector(input, setInput);

  const onSubmit = () => {
    createLiveStreamMutation({
      variables: {
        liveStreamInput: {
          ...input,
          zoomId: `${Math.round(Math.random() * 10000000000)}`, zoomPassword: `${Math.round(Math.random() * 10000000000)}`
        }
      }
    }).then(() => {
      query?.refetch();
      adminMetricsQuery?.refetch();
      window.alert('Live stream created');
    }).catch((err) => {
      window.alert('Error creating live stream');
      console.error(err);
    })
  }

  return (
    <>
      <PageHeader title={`Live Streams (${adminMetricsQuery.data?.adminMetrics.liveStreamCount ?? '-'} total)`} rightItem={<AdminPaginator searchTerm='Live Streams' pagination={pagination} onChange={setPagination} />} />
      <TableContainer>
        <TableWrapper columns={[
          'ID',
          'Zoom ID',
          'Image URL',
          'Start Date',
          'End Date',
          'Title',
          'Description',
          'Archived?',
          'Created At',
          'Actions'
        ]}>
          <GqlQueryRender query={query}>
            {({ adminLiveStreams }) => {
              return (
                <tbody>
                  {adminLiveStreams
                    .map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.zoomId}</td>
                          <td style={{ flexDirection: 'row', display: 'flex' }}>
                            <img src={item.imageUrl} style={{ width: 100, height: 100, marginRight: 10 }} />
                            {item.imageUrl}
                          </td>
                          <td>{formatDate(item.startDate)}</td>
                          <td>{formatDate(item.endDate)}</td>
                          <td>{item.title}</td>
                          <td>{item.description}</td>
                          <td>{formatBoolean(item.archived)}</td>
                          <td>{formatDate(item.createdAt)}</td>
                          <td>
                            <Link to={getIdSluggedPath(ADMIN_BASE + AdminRoutes.LIVE_STREAMS_LISTING, item.id)}>
                              <Button variant='primary'>View</Button>
                            </Link>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              )
            }}
          </GqlQueryRender>
        </TableWrapper>
      </TableContainer>

      <h4>Add New Live Stream</h4>
      <Row style={{ marginBottom: 20 }}>
        <Col md={2}>
          <AppInput type={InputType.TEXT} value={input.imageUrl} onChange={change('imageUrl')} label='Image URL*' />
          <FileDropzone onSuccess={file => {
            change('imageUrl')(file);
          }} accept={{ 'image/*': [] }} />
        </Col>
        <Col md={2}>
          <AppInput type={InputType.DATE} value={input.startDate} onChange={change('startDate')} label='Start At' />
        </Col>
        <Col md={2}>
          <AppInput type={InputType.DATE} value={input.endDate} onChange={change('endDate')} label='End At' />
        </Col>
        <Col md={2}>
          <AppInput type={InputType.TEXT} value={input.title} onChange={change('title')} label='Title' />
        </Col>
        <Col md={2}>
          <AppInput type={InputType.TEXT} value={input.description} onChange={change('description')} label='Description' />
        </Col>
      </Row>
      <Button variant='success' style={{ width: '100%' }} onClick={onSubmit}>Submit</Button>
    </>
  );
}
