import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AdminSidebar } from './components/AdminSidebar';
import { AdminDashboard } from './AdminDashboard';
import { AdminRoutes } from 'shared/Routes';
import { AdminUsers } from './AdminUsers';
import { AdminFeedPosts } from './adminFeedPosts';
import { AdminTeams } from './adminTeams';
import { AdminLiveStreamListing } from './adminLiveStreamListing';
import { AdminProTeams } from './adminProTeams';
import { AdminLiveStreams } from './adminLiveStreams';

interface IProps {
  match?: any;
}

export const AdminRouter: React.FC<IProps> = ({ match }: IProps) => {
  console.log("admin router", match);
  return (
    <AdminSidebar match={match}>
      <Routes>
        <Route path={AdminRoutes.USERS} element={<AdminUsers />} />
        <Route path={AdminRoutes.TEAMS} element={<AdminTeams />} />
        <Route path={AdminRoutes.FEED_POSTS} element={<AdminFeedPosts />} />
        <Route path={AdminRoutes.LIVE_STREAMS_LISTING} element={<AdminLiveStreamListing match={match} />} />
        <Route path={AdminRoutes.PRO_TEAMS} element={<AdminProTeams />} />
        <Route path={AdminRoutes.LIVE_STREAMS} element={<AdminLiveStreams />} />
        <Route path={''} element={<AdminDashboard />} />
      </Routes>
    </AdminSidebar>
  )
}
