import ZoomVideo, { VideoClient } from '@zoom/videosdk'

import React from 'react';
import { PageHeader } from 'components/PageHeader';
import { useLiveStreamQuery } from 'types/gqlReactTypings.generated.d';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

interface IProps {
  match?: any;
}

gql`
  query LiveStream($liveStreamId: Int!) {
    liveStream(liveStreamId: $liveStreamId) {
      id, imageUrl, zoomId, zoomPassword, startDate, endDate, title, description, archived, createdAt, jwtToken
    }
  }
`
export const AdminLiveStreamListing: React.FC<IProps> = () => {
  const { id: liveStreamId } = useParams();
  const [streaming, setStreaming] = React.useState(false);
  const [shareScreen, setShareScreen] = React.useState(false);
  const query = useLiveStreamQuery({ variables: { liveStreamId: parseInt(`${liveStreamId ?? -1}`) }, });
  const [stream, setStream] = React.useState<ReturnType<typeof VideoClient['getMediaStream']>>();

  const camVideoRef = React.useRef<HTMLVideoElement>(null);
  const camCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const screenVideoRef = React.useRef<HTMLVideoElement>(null);
  const screenCanvasRef = React.useRef<HTMLCanvasElement>(null);

  const liveStream = React.useMemo(() => query.data?.liveStream, [query.data?.liveStream]);

  const client = React.useMemo(() => {
    const localClient = ZoomVideo.createClient();
    localClient.init('en-US', 'CDN');
    return localClient;
  }, []);

  React.useEffect(() => {
    if (liveStream != null && liveStream.zoomId != null && liveStream.jwtToken != null) {
      console.info("Joining session", liveStream.zoomId);
      client.join(liveStream.zoomId, liveStream.jwtToken, 'Sample User', liveStream.zoomPassword, 60)
        .then(() => {
          setStream(client.getMediaStream());
        })
        .catch(console.error);
    }

    return () => {
      console.info("Leaving session");
      client.leave();
    }
  }, [liveStream]);

  React.useEffect(() => {
    const vidRefCurr = camVideoRef.current;
    const canvasRefCurr = camCanvasRef.current;
    if (streaming === true && stream != null && vidRefCurr != null && canvasRefCurr != null) {
      stream.startAudio();
      stream.startVideo({ videoElement: vidRefCurr, hd: true }).then(() => {
        stream.renderVideo(canvasRefCurr, client.getCurrentUserInfo().userId, 960, 540, 0, 0, 3)
      }).catch((err) => {
        console.error("Failed to start video", err);
      });
    }

    if (streaming === false && stream != null) {
      stream.stopVideo().catch(console.error);
    }
  }, [streaming, stream, camVideoRef]);

  React.useEffect(() => {
    const vidRefCurr = screenVideoRef.current;
    const canvasRefCurr = screenCanvasRef.current;
    if (shareScreen === true && stream != null && vidRefCurr != null && canvasRefCurr != null) {
      stream.startShareScreen(vidRefCurr,).then(() => {
        console.info("Screen share started")
        vidRefCurr.style.width = '960px';
        vidRefCurr.style.height = 'auto';
      }).catch((err) => {
        console.error("Failed to start share screen", err);
      });
    }

    if (shareScreen === false && stream != null) {
      stream.stopShareScreen().catch(console.error);
      if (vidRefCurr != null) {
        vidRefCurr.style.width = '0px';
        vidRefCurr.style.height = '0px';
      }
    }
  }, [shareScreen, stream, screenVideoRef]);


  console.info(client, stream);

  return (
    <>
      <PageHeader title={`Live Stream #${liveStreamId}`} subtitle={liveStream?.title} />

      <div style={{ marginBottom: 20, flexDirection: 'row' }}>
        <Button variant='primary'
          onClick={() => setStreaming(curVal => !curVal)}
          style={{ marginRight: 20 }}
        >Toggle streaming (currently: {streaming === true ? 'true' : 'false'})</Button >

        <Button variant='warning'
          onClick={() => setShareScreen(curVal => !curVal)}
        >Toggle share screen (currently: {shareScreen === true ? 'true' : 'false'})</Button >
      </div>


      <div style={{ flexDirection: 'row' }}>
        <div>
          <video ref={camVideoRef} id="my-self-view-video"></video>
          <canvas ref={camCanvasRef} id="my-self-view-canvas"></canvas>
        </div>
        <div>
          <video ref={screenVideoRef} id="my-screen-view-video"></video>
          <canvas ref={screenCanvasRef} id="my-screen-view-canvas"></canvas>
        </div>
      </div>

    </>
  );
}
