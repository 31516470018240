import { MetricCard } from 'components/MetricCard';
import gql from 'graphql-tag';
import React from 'react';
import { Row } from 'react-bootstrap';
import { GqlQueryRender } from 'shared/gql/gqlQueryRender';
import { AdminRoutes } from 'shared/Routes';
import { useAdminMetricsQuery } from 'types/gqlReactTypings.generated.d';
import { PageHeader } from '../../components/PageHeader';

gql`
  query AdminMetrics {
    adminMetrics {
      userCount, feedPostCount, teamCount, liveStreamCount
    }
  } 
`

export const AdminDashboard: React.FC = () => {
  const adminMetricsQuery = useAdminMetricsQuery();

  return (
    <div >
      <PageHeader title='Dashboard' />

      <GqlQueryRender query={adminMetricsQuery as any}>
        {({
          adminMetrics: { userCount, feedPostCount, teamCount, liveStreamCount },
        }: any) => {

          return (
            <div>
              <Row>
                <MetricCard color='success' faIcon='user' label='App Users' value={userCount} to={AdminRoutes.USERS} />
                <MetricCard color='primary' faIcon='video' label='Feed Posts' value={feedPostCount} to={AdminRoutes.FEED_POSTS} />
                <MetricCard color='info' faIcon='magnet' label='Teams' value={teamCount} to={AdminRoutes.TEAMS} />
                <MetricCard color='warning' faIcon='stream' label='Live Streams' value={liveStreamCount} to={AdminRoutes.LIVE_STREAMS} />

              </Row>
            </div>
          );
        }}
      </GqlQueryRender>
    </div >
  );
}
